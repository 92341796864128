<template>
  <div class="p-pegas">
    <div
      class="card-table"
      :class="mode ? 'card__tableday' : 'card__tablenight'"
    >
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div
                :class="mode ? 'content__titleday' : 'content__titlenight'"
                class="content-title d-flex align-center mr-2"
              >
                {{ $t("message.guest") }}
              </div>
              <div class="block-sarche">
                <div class="header__search">
                  <crm-input
                    :size="'small'"
                    :className="'w100'"
                    v-model="filterForm.search"
                    :class="mode ? 'input__day' : 'input__night'"
                    :icon="'el-icon-search'"
                    class="mr-2"
                  ></crm-input>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12" class="flex-style text-right">
            <crm-create-and-column-settings
              :permission="$options.name"
              @c-create="drawerCreate = true"
              :class="mode ? 'button__settingsday' : 'button__settingsnight'"
              :columns="columns"
              @c-change="updateColumn"
              :v_can_create="'guests.create'"
            >
            </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->
      <div class="card-table-header table-crm-smart">
        <table
          class="table-my-code table-bordered"
          v-loading="loadingData"
          :class="mode ? 'table__myday' : 'table__mynight'"
        >
          <thead>
            <tr>
              <th class="w50p" v-if="columns.id.show">
                {{ columns.id.title }}
              </th>

              <th v-if="columns.full_name.show">
                {{ columns.full_name.title }}
              </th>

              <th v-if="columns.additional_information.show">
                {{ columns.additional_information.title }}
              </th>

              <th v-if="columns.total_visit.show">
                {{ columns.total_visit.title }}
              </th>

              <th v-if="columns.created_at.show">
                {{ columns.created_at.title }}
              </th>

              <th v-if="columns.settings.show">
                {{ columns.settings.title }}
              </th>
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.id.title"
                  class="id_input"
                ></el-input>
              </th>

              <th v-if="columns.full_name.show">
                <crm-input
                  size="mini"
                  :inputValue="filterForm.full_name"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.full_name"
                  :placeholder="columns.full_name.title"
                ></crm-input>
              </th>

              <th v-if="columns.additional_information.show">
                <crm-input
                  size="mini"
                  :inputValue="filterForm.additional_information"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.additional_information"
                  :placeholder="columns.additional_information.title"
                ></crm-input>
              </th>
              <th v-if="columns.total_visit.show">
                <crm-input
                  :disabled="true"
                  size="mini"
                  :inputValue="filterForm.total_visit"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.total_visit"
                  :placeholder="columns.total_visit.title"
                ></crm-input>
              </th>

              <th v-if="columns.created_at.show">
                <crm-date-picker
                  :placeholder="columns.created_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.created_at"
                  :date="filterForm.created_at"
                  size="mini"
                >
                </crm-date-picker>
              </th>

              <th class="settinW" v-if="columns.settings.show"></th>
            </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr
              v-for="guest in list"
              :key="guest.id"
              class="cursor-pointer"
              @dblclick="edit(guest)"
            >
              <td v-if="columns.id.show">{{ guest.id }}</td>
              <td v-if="columns.full_name.show">
                {{ guest.full_name }}
              </td>
              <td v-if="columns.additional_information.show">
                {{ guest.additional_information }}
              </td>
              <td v-if="columns.total_visit.show">
                {{ guest.total_visit }}
              </td>
              <td v-if="columns.created_at.show">{{ guest.created_at }}</td>
              <td v-if="columns.settings.show" class="settings-td">
                <crm-settings
                  :name="$options.name"
                  :model="guest"
                  :actions="actions"
                  :permissionShow="'guests.update'"
                  :permissionDestroy="'guests.destroy'"
                  @edit="edit"
                  @delete="destroy"
                ></crm-settings>
              </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
        </div>
      </div>
    </div>
    <el-drawer
      :with-header="false"
      :visible.sync="drawerUpdate"
      size="70%"
      ref="drawerUpdate"
      @opened="drawerOpened('drawerUpdateChild')"
      @closed="drawerClosed('drawerUpdateChild')"
    >
      <crm-update
        :selectedItem="selectedItem"
        ref="drawerUpdateChild"
        drawer="drawerUpdate"
      ></crm-update>
    </el-drawer>
    <div class="app-modal app-modal__full">
      <el-drawer
        :with-header="false"
        :visible.sync="drawerCreate"
        size="70%"
        ref="drawerCreate"
        @opened="drawerOpened('drawerCreateChild')"
        @closed="drawerClosed('drawerCreateChild')"
      >
        <crm-create ref="drawerCreateChild" drawer="drawerCreate"></crm-create>
      </el-drawer>
    </div>
  </div>
</template>
<script>
import CrmShowDetails from "./components/crm-show-details";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import list from "@/utils/mixins/list";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  mixins: [list],
  name: "GuestController",
  components: {
    CrmShowDetails,
    CrmCreate,
    CrmUpdate
  },
  data() {
    return {
      drawer: false,
      currentPage2: 5,
      checkList: [],
      form: {},
      sarcheBlocks: ""
    };
  },
  computed: {
    ...mapGetters({
      list: "guest/list",
      columns: "guest/columns",
      pagination: "guest/pagination",
      statues: "guest/statues",
      filter: "guest/filter",
      sort: "guest/sort",
      mode: "MODE"
    }),
    actions: function() {
      return ["edit", "delete"];
    }
  },
  methods: {
    ...mapActions({
      updateList: "guest/index",
      setPagination: "guest/setPagination",
      updateSort: "guest/updateSort",
      updateFilter: "guest/updateFilter",
      updateColumn: "guest/updateColumn",
      updatePagination: "guest/updatePagination",
      show: "guest/show",
      empty: "guest/empty",
      delete: "guest/destroy",
      refreshData: "guest/refreshData"
    }),
    openDrawer() {
      this.appModal = !this.appModal;
    }
  }
};
</script>
